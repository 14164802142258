export default {
    name: "Monitor",
    created() {
        this.$nextTick(function () {

        });
    },
    mounted() {
        this.$nextTick(function () {
            // this.$$.navbarAnimation()
        })
    },
    data() {
        return {
            MonitorC: [
                {
                    name: 'OST Maybank2u Website',
                    data: [
                        `After purchasing your structured warrants, for ease of monitoring your positions and holdings you can select and add the purchased structured warrants into your <i>favourite list.</i>`,
                        `You can have multiple favourites lists according to your specifications be it sector-based,existing structured warrants positions or type of structured warrants.`,
                        `Having a favourite list makes it easier to monitor the movement of the structured warrants.`,
                        `Otherwise,you would have to go to Online Enquiries > <i>My Portfolio</i> to see your current holdings of stocksand structured warrants. Pricesshown are as at the time stamp that you enter.`
                    ]
                },
                {
                    name: 'Maybank Trade',
                    data: [
                        `Structured warrants that you have purchased will appear on the app's "<i>Stock Portfolio</i>" tab.`,
                        `From that tab, you're able to see your current holdings both stocks and structured warrants with info such as quantity held, market value, last price,unrealised profit and loss.`,
                        `You can also <i>set alerts</i> for your Structured Warrants based on price or% change with conditions pre-set that if it moves above or below the value that you input.`,
                        `Alerts can last up to a maximum of 30 days and you can include notes to be shown.`
                    ]
                }
            ]
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {

    }
};